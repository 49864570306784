<template>
	<CRow>
		<CCol sm="12" v-if="showDetailPage == false">
			<ListForm :headerTitle="$t('pages.account.userList')" :onSearch="onSearch">
				<template v-slot:headerLeft>
					<button type="button" name="add-account" class="btn btn-success" @click="onAddNewAccount">
						{{ $t('pages.account.addNewUser') }}
					</button>
				</template>
				<template v-slot:searchBox>
					<CForm>
						<CRow>
							<CCol md="6">
								<CInput :label="$t('common.userName')" v-model="searchInfo.userName" horizontal></CInput>
							</CCol>
							<CCol md="6">
								<CInput :label="$t('common.email')" v-model="searchInfo.email" horizontal></CInput>
							</CCol>
						</CRow>
						<CRow>
							<CCol md="6">
								<CInput :label="$t('common.phone')" v-model="searchInfo.phone" horizontal></CInput>
							</CCol>
							<CCol md="6">
								<div class="form-group form-row">
									<div class="col-md-3">
										<label>Loại tài khoản</label>
									</div>
									<div class="col-md-9">
										<!--<select class="form-control" v-model="searchInfo.roleId">
											<option value="0" selected="selected">Chọn loại tài khoản</option>

											<option v-for="(item) in roles" v-bind:key="item.id" v-bind:value="item.id">
												{{item.displayName}}
											</option>
										</select>-->
										<CSelect horizontal :options="roleOptions" :value.sync="searchInfo.roleId"></CSelect>
									</div>
								</div>
							</CCol>
						</CRow>
					</CForm>
				</template>
				<template v-slot:list>
					<div class="d-flex" v-if="totalPages != null && totalPages > 0">
				
					</div>

					<CDataTable :items="userList"
								:fields="fields"
								:noItemsView="{ noItems: $t('common.nodatafound') }"
								hover
								striped
								border
								small
								fixed								
								:loading="isLoading"
								pagination
								:items-per-page="itemsPerPage"
								@pagination-change="onItemsPerPageChange"
								:items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
							

							<template #theadTopSlot v-if="userList != null && userList.length > 0">
							<div>{{totalItems}}</div>
						</template>
								
						<template #footer v-if="userList != null && userList.length > 0">
							<tfoot>
								<tr>
									<td :colspan="8">
										<span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{totalItems}}</span>
									</td>
								</tr>
							</tfoot>
						</template>
						<template #account_status="{item}">
							<td class="py-2">
								<CIcon :name="accountIsOnlineIcon(item.isOnline)"/>
								<CIcon :name="accountClosedIcon(item.isClosed)"/>
								<CIcon :name="accountStatusIcon(item.profileStatus)" />
							</td>
						</template>
						<template #user_detail_actions="{item, index}">
							<td class="py-2">
								<CButton color="primary"
										 variant="outline"
										 square
										 size="sm"
										 @click="onViewDetailUser(item, index)">
									{{ $t('common.detail') }}
								</CButton>
								<CButton color="primary"
										 variant="outline"
										 square
										 size="sm"
										 @click="onDeleteUser(item, index)">
									{{ $t('common.remove') }}
								</CButton>
							</td>
						</template>

						<template #over-table>
							<CPagination
								:activePage.sync="page"
								@update:activePage="onPageChange"
								:pages="totalPages"
								v-show="totalPages > 1"
								size="sm"
								align="center" 
								/>
								Trang hiện tại: {{pageIndex + 1}}
								Tổng số trang: {{totalPages}}
						</template>

						<template #under-table>
							<CPagination
								:activePage.sync="page"
								@update:activePage="onPageChange"
								:pages="totalPages"
								v-show="totalPages > 1"
								size="sm"
								align="center" 
								/>
								Trang hiện tại: {{pageIndex + 1}}
								Tổng số trang: {{totalPages}}
						</template>
					</CDataTable>			
				</template>
			</ListForm>
			<Confirmation ref="confirmation"></Confirmation>
		</CCol>
	</CRow>
	
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
	import ListForm from '@/components/ListForm.vue'
	import UserDetail from './UserDetail';

    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
	import { mapState, mapActions, mapGetters } from 'vuex'
    import { Role } from '@/helpers/role';

    export default ({
        name: 'Table',
        data() {
            return {     
                searchUserName: '',
                searchEmail: null,
                searchPhoneNumber: null,
                searchRole: null,
				allUsers: {},

				showDetailPage: false,                         
                itemsPerPage: 10,
                fields: [
                    { key: 'userName', label: i18n.t('common.userName') },
                    { key: 'fullName', label: i18n.t('pages.account.fullName') },
                    { key: 'email', label: i18n.t('common.email') },
                    { key: 'phoneNumber', label: i18n.t('common.phone') },
					{ key: 'address', label: i18n.t('common.address') },
					{ key: 'account_status', label: i18n.t('common.status') },
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
				],
                isAdUserType: false,

				isLoadingUserInfo: false,
				currentUserDetail: null,
				logInUser: null,
            };
        },
        components: {
			ListForm,
            Confirmation,
        },
        computed: {
            ...mapState('account', ['searchInfo', 'isLoading', 'userList','totalItems', 'totalPages', 'pageIndex']),
			...mapState('role', ['roles']),
			page: {
				get() {
					return this.pageIndex + 1;
				},
				set(newVal) {
					
				}
				
			},
			roleOptions() {
                
				let options = [];
                let filteredRoles = [];
                if (this.roles != null && this.logInUser != null) {
                    var currentRole = this.roles.find(el => el.name == this.logInUser.role);
                   
                    if (currentRole != null && currentRole.name === Role.Admin)
					{
                        filteredRoles = this.roles;
                    }
					else {
                        
                        filteredRoles = this.roles.filter(el => el.level > currentRole.level);                        
                    }
                }               
                options = filteredRoles.map(item => {
                    return { value: item.id, label: item.displayName };
				});
                
                options.splice(0, 0,
                    {
                        value: '0',
                        label: '---Chọn loại tài khoản---'
                    });

                return options;
            },
        },

        methods: {
			...mapActions('account', ['searchUsers', 'initSearchInfo']),
            ...mapGetters('auth', ['user']),
			...mapActions('role', ['getAllRoles']),

			onCancelLoading() {

			},
			onItemsPerPageChange(item) {
                this.itemsPerPage = item;
                localStorage.setItem('userItemsPerPage', item)

                if(this.page > 0){
					this.searchUsers({pageIndex: this.page-1, pageSize: this.itemsPerPage});
                }
            },
			onPageChange(newPageNumber){
				this.searchUsers({pageIndex: newPageNumber-1, pageSize: this.itemsPerPage});
            },
			accountIsOnlineIcon(isOnline) {
				return isOnline ? 'cilMediaPause' : 'cilMediaPlay'
			},
			accountStatusIcon(status) {
				return status === 1 ? 'cilUserX'
                    : status === 2 ? 'cilCheck'
                        : 'cilXCircle'
			},
			accountClosedIcon(isClosed) {
				return isClosed ? 'cilLockLocked': 'cilLockUnlocked';
			},
			
            accountStatusText(status) {
                return status === 1 ? i18n.t('pages.account.waitingForVerification')
                    : status === 2 ? i18n.t('pages.account.verified')
                        : i18n.t('pages.account.notVerified')
            },
            accountStatusCss(status) {               
                if (status === 2)
                    return "btn-success";
                else
                    return "btn-danger";
			},
            accountClosedText(isclosed) {
                return isclosed ? i18n.t('common.closed') : i18n.t('common.active')
            },
			accountClosedCss(isclosed) {
               
                if (isclosed)
                    return "btn-danger";
                else
                    return "btn-success";
            },
            
			onSearch() {                               
                this.searchUsers({pageIndex: 0, pageSize: this.itemsPerPage});
            },
           
            onDeleteUser(item) {
                this.$refs.confirmation.show(i18n.t('pages.account.confirmDelete'), async () => {

                    var retVal = await this.$store.dispatch("account/deleteAccount", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
            onEditUser(item) {
                this.$router.push('/users/edit/' + item.id);              
			},
            async onViewDetailUser(item) {
				//this.showDetailPage = !this.showDetailPage;
				// this.isLoadingUserInfo = true;
				
				
				// var accountDetailResponse = await this.$store.$accountService.getAccountProfileById(item.id);
	
				// if(!accountDetailResponse.success) 
				// {
				// 	this.isLoadingUserInfo = false;
				// 	this.currentUserDetail = null;
				// 	return;
				// }
				
				// var accountMediaDetailResponse = await this.$store.$accountService.getAllIdentityIdCardImages(item.id);
				// var avatarResponse = await this.$store.$accountService.getAvatarToUser({userId: item.id});
				
				// var uInfo = accountDetailResponse.data;
				// if(accountMediaDetailResponse.success == true) {
				// 	uInfo.idCardImages = accountMediaDetailResponse.data;
				// }

				// if(avatarResponse.success == true) {
				// 	uInfo.avatarImage = avatarResponse.data;
				// }
				// this.currentUserDetail = uInfo;
				// this.isLoadingUserInfo = false;
				// this.showDetailPage = true;
                this.$router.push('/users/detail/' + item.id);
            },
			onAddNewAccount() {
                this.$router.push('/users/add');
			},
          
		

			backTest () {
				this.showDetailPage = false;
			}
		},
		created() {
			this.logInUser = this.$store.state.auth.user;
            if (this.logInUser != null && this.logInUser.role === 'admin')
                this.isAdUserType = true;

			this.initSearchInfo();
			this.onSearch();

            let _iTemPerPage = parseInt(localStorage.getItem('userItemsPerPage'));
            if (isNaN(_iTemPerPage))
                _iTemPerPage = 10;
            this.itemsPerPage = _iTemPerPage;
			if(this.roles == null || this.roles.length == 0) {
				this.getAllRoles();
			}
        }
    })

</script>