<template>
	<CRow>
		<CCol sm="6">
			<CForm v-if="userInfo != null">
				<CInput :label="$t('common.userName')" :value="userInfo.userName" horizontal></CInput>
				<CInput :label="$t('pages.account.fullName')" :value="userInfo.fullName" horizontal></CInput>
				<CInput :label="$t('common.email')" :value="userInfo.email" horizontal></CInput>
				<CInput :label="$t('common.phone')" :value="userInfo.phone" horizontal></CInput>
				<CInput :label="$t('common.address')" :value="userInfo.address" horizontal> </CInput>
				<CInput :label="$t('pages.account.gender')" :value="showgender(userInfo.gender)" horizontal> </CInput>
				<CInput :label="$t('pages.account.cmnd')" :value="userInfo.cmnd" horizontal> </CInput>
				<CInput :label="$t('pages.account.dayOfBirth')" :value="formatDate(userInfo.dayOfBirth)" horizontal> </CInput>

				
			</CForm>
			
			<div v-else>
				<p>Không có dữ liệu</p>
			</div>
		</CCol>
	</CRow>
</template>

<script>
	import i18n from '@/plugins/i18n'
	
	export default {
        name: 'UserDetail',
		props: {
            userInfo: {
                default: null,
            },
        },
		data() {
			return {};
		},
        components: {
          
        },
        computed: {            
			
        },  
		methods: {   
			formatDate(date) {
                if (date === null || date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
            },                   
            showgender(gender) {
				if (gender === 1)
					return i18n.t('pages.account.male');
                else if (gender === 2)
                    return i18n.t('pages.account.female');
				else 
					return '';
			},
		},
		created() {          
			console.log('User Detail Component mounted');
		}
	}
</script>